
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Auth } from 'aws-amplify';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "..";
import { ElNotification } from "element-plus";
import router from '@/router'
import changepassword from '@/views/settings/user/ChangePassword.vue'
import newpassword from '@/views/auth/NewPassword.vue'
import { use } from "element-plus/lib/locale";
import { object } from "yup";
import axios from "axios";
// import { useStore } from "vuex";
// import { mapMutations } from 'vuex';

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
  phoneNumber: string;
  orgRole: string;
  userRole: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];

    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    window.localStorage.clear();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      // ApiService.post("login", credentials)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //     resolve();
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     reject();
      //   });
      // Auth.signIn(credentials.email, credentials.password)
      // // console.log(user);
      // .then(( data ) => {
      //       this.context.commit(Mutations.SET_AUTH, data);
      //       store.commit('setUserData',data);
      //       // console.log('authmodule',data);
      //       resolve();
      //     })
      //     .catch(( response ) => {
      //       // console.log(response);
      //       // this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //       // console.log("failed");
      //       Swal.fire({
      //                       text: response.message,
      //                       icon: "error",
      //                       buttonsStyling: false,
      //                       confirmButtonText: "Try again!",
      //                       customClass: {
      //                         confirmButton: "btn fw-bold btn-light-danger",
      //                       },
      //                     })
      //       reject();
      //     });
     
      window.localStorage.clear();

      Auth.signIn(credentials.email, credentials.password)

      .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // this.user=user
            this.context.commit(Mutations.SET_AUTH, user);
            // store.commit('setUserData',user);
            router.push({ name: "newpassword" });

          } else {

              this.context.commit(Mutations.SET_AUTH, user);
              let url=process.env.VUE_APP_SERVICE_URL == undefined ? "https://m1nc4q24th.execute-api.ap-south-1.amazonaws.com/platform-admin-dev/v1/" : process.env.VUE_APP_SERVICE_URL
              url=url + 'userDetails'
              axios.get(url, {headers:{'Authorization':user["signInUserSession"]["accessToken"]["jwtToken"]}})
              .then(({ data }) => {
                if (data.errors.length != 0) {
                  // alert("API Keys is not generated with this " + data.errors[0].args);
                } else {
                    localStorage.setItem("organizationId", data['data']['id'])
                    store.commit('setUserData', data);
                  resolve();
                  
                }
              })
              .catch(({ error }) => {
                // loading.close();
                // if(error.response.status===401)
                //  {
                //     router.push({ name: "sign-in" });
                //  }
              });
              // typeof(user);
              // store.commit('setUserData',data);
              // resolve();
          }
      }).catch(e => {
        ElNotification({
          title: 'Error',
          message: e.message,
          type: 'error',
          duration: 2000,
          position: 'top-right'
        })
        reject();
      });

    });

  }

  @Action
  [Actions.LOGOUT]() {
    window.localStorage.clear();
    
    Auth.signOut();
 
    // store.dispatch('entities/deleteAll');
    localStorage.clear()
    
    store.commit('clearUserData');

    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/forgotPassword?emailId=" +
      payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
                            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // ApiService.get("verify")
      //   .then(( data ) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(( response ) => {
      //     this.context.commit(Mutations.SET_ERROR, response.message);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}