import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

import createPersistedState from "vuex-persistedstate";
import { UserRoles, OrgRoles } from "../helper/role";

config.rawError = true;

const store = createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
  state() {
    return {
      name: "",
      email: "",
      // emailVerified: "",
      phoneNumber: "",
      phoneNumberVerified: "",
      organizationId: "",
      organizationName:"",
      orgRole: "",
      userRole: "",
      // category: ""
    };
  },
  mutations: {
    setUserData(state: any, payload) {
      state.name = payload.data.name;
      state.email = payload.data.email;
      // if (payload.data.email_verified == true) {
      //   state.emailVerified = "Verified";
      // } else {
      //   state.emailVerified = "Not Verified";
      // }
      
      // if (payload.attributes.phone_number_verified == true) {
      //   state.phoneNumberVerified = "Verified";
      // } else {
      //   state.phoneNumberVerified = "Not Verified";
      // }
      // const organizationRoles = JSON.parse(
      //   payload.attributes["custom:cognitoAttributes"]
      // );

      // for (const roles in OrgRoles) {
      //   state.orgRole[OrgRoles[roles]] = false;
      // }
      // for (const roles in UserRoles) {
      //   state.userRole[UserRoles[roles]] = false;
      // }
      
      // organizationRoles.userRole = organizationRoles.userRole
      //   .toString()
      //   .toLowerCase();
      state.orgRole = payload.data.organizationType;
      state.userRole = payload.data.roleType;
      state.organizationId = payload.data.id;
      state.organizationName=payload.data.orgName
      state.phoneNumber=payload.data.mobileNumber;
      // state.category='insurance';
    },
    clearUserData(state:any)
    {
        state.name = "";
        state.email = "";
        // state.emailVerified = "";
        state.phoneNumber = "";
        state.phoneNumberVerified = "";
        state.organizationId = "";
        state.organizationName="",
        state.orgRole = "";
        state.userRole = "";
        // state.category = "";
    }
  }
  // plugins: [
  //   createPersistedState({
  //     getState: (key) => Cookies.getJSON(key),
  //     setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
  //   })
  // ]
});

export default store;
