const UserRoles = {
    ADMIN : 'Admin',
    DEVELOPER : 'Dev',
}

const OrgRoles = {
    CONSUMER : 'consumer',
    MANUFACTURER : 'manufacturer',
    API_PROVIDER : 'api_provider',
    ONE_SB : '1SB'
}

const TOTAL_USER_ROLES = 2

export { UserRoles, OrgRoles, TOTAL_USER_ROLES }