import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";
import router from "@/router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    // ApiService.vueInstance.axios.defaults.baseURL = "http://localhost";
    // ApiService.vueInstance.axios.defaults.baseURL = "http://127.0.0.1:8000/v1/";
     // while running locally uncomment the below line code 26th-27th  and comment the 29th line
    //  ApiService.vueInstance.axios.defaults.baseURL =
    //    "https://m1nc4q24th.execute-api.ap-south-1.amazonaws.com/platform-admin-dev/v1/";
     // while making pull request / merging code, uncomment 29th line code and comment the 26th-27th line code
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL;
    
  }

  /**
   * @description set the default HTTP request headers
   * 
   */
  public static setHeader(): void {
    // const token = JwtService.getTokens();
    Auth.currentSession().then(
      (data) =>
        (ApiService.vueInstance.axios.defaults.headers.common[
          "Authorization"
        ] = `${data.getAccessToken().getJwtToken()}`)
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params)
    // .catch((error) => {
      // throw new Error(`[KT] ApiService ${error}`);
    // });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string
    // slug = "" as string
  ): Promise<AxiosResponse> {
    //     const status_code=  ApiService.vueInstance.axios.get(`${resource}`)
    // console.log(status_code.then((data) => {
    //   if(data.request.status===401)
    //   {
    //     router.push({ name: "sign-in" });
    //     }}))
    return ApiService.vueInstance.axios.get(`${resource}`)
    // .catch((error) => {
    //   throw new Error(`[KT] ApiService ${error}`);
    ;
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  public static patch(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
