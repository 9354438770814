import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { UserRoles, OrgRoles, TOTAL_USER_ROLES } from "../helper/role";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    meta:{requiresAuth: false},
    children: [

      //CMS
      {
        path: "/settings/account/ConfigureCredentials",
        name: "Credentials Configuration",
        component: () => import("@/views/settings/account/ConfigureCredentials.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
        },
      },
      {
        path: "/settings/account/ConfigureApiEndPoints",
        name: "API End Point Configuration",
        component: () => import("@/views/settings/account/ConfigureApiEndPoint.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/apidocs/:category/:type",
        name: "apidocs",
        component: () => import("@/views/settings/account/ApiDocs.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          } 
          // ,
          // orgRoleAuth: [OrgRoles.ONE_SB, OrgRoles.CONSUMER],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          // ],
        },
      },
      // {
      //   path: "/settings/account/organization",
      //   name: "organization",
      //   component: () => import("@/views/settings/account/Organization.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     orgRoleAuth: [OrgRoles.ONE_SB],
      //     userRoleAuth: [[UserRoles.ADMIN]],
      //   },
      // },
      {
        path: "/settings/account/accountoverview",
        
        name: "accountoverview",
        
        component: () => import("@/views/settings/account/AccountOverview.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.ONE_SB] :
	        {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
	        {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
	        {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
        children: [
          {
            path: "/settings/account/accountoverview/organizationprofile",
            name: "organizationProfile",
            component: () =>
              import("@/views/settings/account/OrganizationProfile.vue"),
            meta: {
              requiresAuth: true,
              [OrgRoles.CONSUMER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.MANUFACTURER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.API_PROVIDER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              // orgRoleAuth: [
              //   OrgRoles.CONSUMER,
              //   OrgRoles.MANUFACTURER,
              //   OrgRoles.API_PROVIDER,
              // ],
              // userRoleAuth: [
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              // ],
            },
          },
          {
            path: "/api/accountoverview/apikey",
            name: "apikey",
            component: () => import("@/views/settings/account/ApiKey.vue"),
            meta: {
              requiresAuth: true,
              [OrgRoles.CONSUMER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              // orgRoleAuth: [OrgRoles.CONSUMER],
              // userRoleAuth: [[UserRoles.ADMIN]],
            },
          },
          {
            path: "/settings/account/accountoverview/manufacturerslist",
            name: "manufacturersList",
            component: () =>
              import("@/views/settings/account/ManufacturersList.vue"),
            meta: {
              requiresAuth: true,
              [OrgRoles.CONSUMER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.MANUFACTURER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.API_PROVIDER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              // orgRoleAuth: [
              //   OrgRoles.CONSUMER,
              //   OrgRoles.MANUFACTURER,
              //   OrgRoles.API_PROVIDER,
              // ],
              // userRoleAuth: [
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              // ],
            },
          },
          {
            path: "/settings/account/accountoverview/consumerslist",
            name: "consumersList",
            component: () =>
              import("@/views/settings/account/ConsumersList.vue"),
            meta: {
              requiresAuth: true,
              [OrgRoles.CONSUMER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.MANUFACTURER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              [OrgRoles.API_PROVIDER] :
              {
                [UserRoles.ADMIN] : true, 
              },
              // orgRoleAuth: [
              //   OrgRoles.CONSUMER,
              //   OrgRoles.MANUFACTURER,
              //   OrgRoles.API_PROVIDER,
              // ],
              // userRoleAuth: [
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              //   [UserRoles.ADMIN],
              // ],
            },
          },
        ],
      },
      {
        path: "/settings/account/manageorganization",
        name: "manageorganization",
        component: () =>
          import("@/views/settings/account/ManageOrganization.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
         

          // orgRoleAuth: [OrgRoles.ONE_SB],
          // userRoleAuth: [[UserRoles.ADMIN]],
        },
      },
      {
        path: "/settings/account/attributemaster",
        name: "attributemaster",
        component: () => import("@/views/settings/account/AttributeMaster.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          
        },
      },
      {
        path: "/settings/account/ruleengine",
        name: "ruleengine",
        component: () => import("@/views/settings/account/RuleEngine.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          
        },
      },
      {
        path: "/settings/account/usermanagement",
        name: "usermanagement",
        component: () => import("@/views/settings/account/UserManagement.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/api/health-dashboard",
        name: "health-dashboard",
        component: () => import("@/views/settings/account/HealthDashboard.vue"),
        meta: { requiresAuth: false },
      },
      // {
      //   path: "/api/kpi-detail",
      //   name: "kpi-detail",
      //   component: () => import("@/views/settings/account/KPIDetail.vue"),
      // },
      {
        path: "/api/kpi-dashboard",
        name: "kpi-dashboard",
        component: () => import("@/views/settings/account/KPIDashboard.vue"),
      },
      {
        path: "/api/audittrail",
        name: "audittrail",
        component: () => import("@/views/settings/account/AuditTrail.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/settings/account/communicationpreference",
        name: "communicationpreference",
        component: () =>
          import("@/views/settings/account/CommunicationPreference.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/support",
        name: "Support",
        component: () => import("@/views/Support.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/ticket/:ticketId",
        name: "Ticket",
        component: () => import("@/views/Ticket.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/communicationService/channel",
        name: "Communication Service",
        component: () => import("@/views/communicationService/channel.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/communicationService/service",
        name: "Communication Service",
        component: () => import("@/views/communicationService/service.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/communicationService/event",
        name: "Event",
        component: () => import("@/views/communicationService/event.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/communicationService/emailConfiguration",
        name: "Email Configuration",
        component: () => import("@/views/communicationService/emailConfiguration.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/communicationService/smsConfiguration",
        name: "Sms Configuration",
        component: () => import("@/views/communicationService/smsConfiguration.vue"),
        meta: { requiresAuth: false },
      },
      
      {
        path: "/faq",
        name: "FAQ",
        component: () => import("@/views/FAQ.vue"),
        meta: { requiresAuth: false },
      },
      // {
      //   path: "/webPage",
      //   name: "webPage",
      //   component: () => import("@/views/api/webPage.vue"),
      //   meta: { requiresAuth: false },
      // },
      // {
      //   path: "/example",
      //   name: "example",
      //   component: () => import("@/views/api/example.vue"),
      //   meta: { requiresAuth: false },
      // },
      
      {
        path: "/setting/user/profile",
        name: "profile",
        component: () => import("@/views/settings/user/Profile.vue"),
        meta: { requiresAuth: false },
      },
    
      {
        path: "/setting/user/changepassword",
        name: "changepassword",
        component: () => import("@/views/settings/user/ChangePassword.vue"),
        meta: { requiresAuth: false },
      },

      {
        path: "/api/apilib/apilist",
        name: "apilist",
        component: () => import("@/views/api/apilibrary/ApiList.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          // orgRoleAuth: [OrgRoles.ONE_SB, OrgRoles.CONSUMER],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          // ],
        },
      },
      {
        // the 404 route, when none of the above matches
        path: "/403",
        name: "403",
        component: () => import("@/views/error/Error403.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/setting/billing/summary",
        name: "summary",
        component: () => import("@/views/settings/billing/Summary.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/setting/billing/invoice",
        name: "invoice",
        component: () => import("@/views/settings/billing/Invoice.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/setting/billing/payment",
        name: "payment",
        component: () => import("@/views/settings/billing/Payment.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/api/apimonitor/analytics",
        name: "analytics",
        component: () => import("@/views/api/apimonitoring/Analytics.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/api/apimonitor/analyticsManufacturer",
        name: "analyticsManufacturer",
        component: () => import("@/views/api/apimonitoring/AnalyticsManufacturer.vue"),
        meta: {
          requiresAuth: true,
         
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/api/apimonitor/logs",
        name: "Logs",
        component: () => import("@/views/api/apimonitoring/Logs.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.API_PROVIDER] :
          {
            [UserRoles.ADMIN] : true, 
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.API_PROVIDER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      // {
      //   path: "/api/apilib/apilogs",
      //   name: "apilogs",
      //   component: () => import("@/views/api/apimonitoring/Logs.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     [OrgRoles.CONSUMER] :
      //     {
      //       [UserRoles.ADMIN] : true, 
      //       [UserRoles.DEVELOPER] :true
      //     },
      //     [OrgRoles.ONE_SB] :
      //     {
      //       [UserRoles.ADMIN] : true, 
      //       [UserRoles.DEVELOPER] :true
      //     },
      //     // orgRoleAuth: [OrgRoles.ONE_SB, OrgRoles.CONSUMER],
      //     // userRoleAuth: [
      //     //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
      //     //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
      //     // ],
      //   },
      // },
      {
        path: "/api/apisubsciption/subscribe",
        name: "subscribe",
        component: () => import("@/views/api/apisubscription/Subscribe.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
          },
            
          // orgRoleAuth: [OrgRoles.CONSUMER],
          // userRoleAuth: [[UserRoles.ADMIN]],
        },
      },
      {
        path: "/api/apisubsciption/apiusage",
        name: "apiusage",
        component: () => import("@/views/api/apisubscription/ApiUsage.vue"),
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
      },
      {
        path: "/settings/configureconsumer",
        name: "configureconsumer",
        component: () => import("@/views/api/apiconfiguration/ConfigureConsumer.vue"),
        // meta: { requiresAuth: true, orgRoleAuth: [ OrgRoles.MANUFACTURER, OrgRoles.ONE_SB], userRoleAuth: [[UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN]] },
        meta: {
          requiresAuth: true,
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
        
      },
      {
        path: "/settings/configuremanufacturer",
        name: "configuremanufacturer",
        component: () => import("@/views/api/apiconfiguration/ConfigureManufacturer.vue"),
        // meta: { requiresAuth: true, orgRoleAuth: [ OrgRoles.MANUFACTURER, OrgRoles.ONE_SB], userRoleAuth: [[UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN]] },
        meta: {
          requiresAuth: true,
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
        
      },

      {
        path: "/settings/organizationapis",
        name: "organizationapis",
        component: () => import("@/views/api/apiconfiguration/OrganizationAPIs.vue"),
        // meta: { requiresAuth: true, orgRoleAuth: [ OrgRoles.MANUFACTURER, OrgRoles.ONE_SB], userRoleAuth: [[UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN, UserRoles.DEVELOPER], [UserRoles.ADMIN]] },
        meta: {
          requiresAuth: true,
          [OrgRoles.MANUFACTURER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.CONSUMER] :
          {
            [UserRoles.ADMIN] : true, 
            [UserRoles.DEVELOPER] :true
          },
          [OrgRoles.ONE_SB] :
          {
            [UserRoles.ADMIN] : true, 
            
          },
          // orgRoleAuth: [
          //   OrgRoles.CONSUMER,
          //   OrgRoles.MANUFACTURER,
          //   OrgRoles.ONE_SB,
          // ],
          // userRoleAuth: [
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN, UserRoles.DEVELOPER],
          //   [UserRoles.ADMIN],
          // ],
        },
        
      },
    ],
  },


  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/views/auth/Auth.vue"),
    meta: { requiresAuth: false },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/newpassword",
        name: "newpassword",
        component: () => import("@/views/auth/NewPassword.vue"),
        meta: { requiresAuth: false },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/auth/ForgotPassword.vue"),
      },

    ],
  },
  
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route changle
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (Object.keys(to.meta).length != 0 && to.meta.constructor === Object) {
    if (to.meta.requiresAuth) {
      const orgRole = store.state.orgRole;
      const userRole = store.state.userRole;
      const orgUserRole: any = to.meta;
      if(orgRole || userRole)
      {

      
      if(orgUserRole[orgRole][userRole]==true)
      {
        next();
      }
      else
      {
        next({ path: "/403" });
      }
    }
    else
    {
      next({ path: "/sign-in" });
    }
      // const orgRoleArray: any = to.meta.orgRoleAuth;
      // const userRoleArray: any = to.meta.userRoleAuth;

      // let index;
      // for (index = 0; index < orgRoleArray.length; index++) {
      //   if (orgRole[orgRoleArray[index]] == true) {
      //     break;
      //   }
      // }
      // if (index != orgRoleArray.length) {
      //   // total no. of user roles
      //   if (userRoleArray[index].length == TOTAL_USER_ROLES) {
      //     next();
      //   } else {
      //     let userRoleIndex;
      //     const userArray = userRoleArray[index];
      //     for (
      //       userRoleIndex = 0;
      //       userRoleIndex < userArray.length;
      //       userRoleIndex++
      //     ) {
      //       if (userRole[userArray[userRoleIndex]] == true) {
      //         break;
      //       }
      //     }
      //     if (userRoleIndex != userRoleArray[index].length) {
      //       next();
      //     } else {
      //       next({ path: "/403" });
      //     }
      //   }
      // } else {
      //   next({ path: "/403" });
      // }
    } else {
      next();
    }
  } else {
    next({ path: "/403" });
  }
});

export default router;
